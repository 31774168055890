import {Component} from './Index.js';
import {useEffect, useState} from 'react';
// import { Collapse } from './Utils.js';
import '../design/css/training.css'
import TableWithoutInput from './NoinputTable.js';
import axios from 'axios';

import img7 from '../design/img7.jpeg';


export default function Index() {

    function iso_week_number(date) {

        const year = date.getFullYear();
        const beginYear = new Date(year, 0, 1);

        // Adjust to the first Thursday of the year
        const dayOfWeek = beginYear.getDay();
        const adjustment = (dayOfWeek <= 4) ? -dayOfWeek + 1 : 8 - dayOfWeek;
        const firstThursday = new Date(year, 0, 1 + adjustment);

        // Calculate the ISO week number
        const diff = date - firstThursday;
        const daysInWeek = 7;
        const week = Math.floor(diff / (daysInWeek * 24 * 60 * 60 * 1000)) + 1;

        // Handle edge cases where the week number might be 0 (before the first Thursday of the year)
        return (week <= 0 ? 52 : week);
    }

    const currentDate = new Date();
    const weekNumber = iso_week_number(currentDate);
  
    const evenWeeks = (
        <div style={{textAlign:"center"}}>
            <h3>Even weken</h3>
            <p>Maandag</p>
            <TableWithoutInput even={1} day={"Maandag"}/>

            <p>Donderdag</p>
            <TableWithoutInput even={1} day={"Donderdag"}/>
        </div>
    )

    const unevenWeeks = (
        <div style={{textAlign:"center"}}>
            <h3>Oneven weken</h3>
            <p>Maandag</p>
            <TableWithoutInput even={0} day={"Maandag"}/>

            <p>Donderdag</p>
            <TableWithoutInput even={0} day={"Donderdag"}/>
        </div>
    )

    return (

        <Component title={"Training"} img={img7} component={<>
            <h4>This week is an {weekNumber % 2 == 0 ? "even" : "uneven"} week!</h4>
            {weekNumber % 2 === 0 ? <>{evenWeeks}{unevenWeeks}</> : <>{unevenWeeks}{evenWeeks}</>}
        </>
        }/>

    )


}
